<template>
  <div class="form-cms mb-2">
    <SkeletonInput />
    <div class="fields-flex">
      <SkeletonInput />
      <SkeletonInput />
    </div>

    <div class="fields-flex">
      <SkeletonInput />
      <SkeletonInput />
    </div>

    <div class="fields-flex">
      <SkeletonInput />
      <SkeletonInput />
    </div>

    <div class="fields-flex">
      <div class="text-center">
        <Skeleton skeleton="image" />
        <Skeleton skeleton="button" />
      </div>
      <div class="">
        <SkeletonInput />
        <SkeletonInput />
        <Skeleton skeleton="button" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Skeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Skeleton" */ '@/modules/cms/components/skeletons/Skeleton.vue'
      )
    ),
    SkeletonInput: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SkeletonFormControl" */ '@/modules/cms/components/skeletons/SkeletonInput.vue'
      )
    ),
    SkeletonTextarea: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SkeletonTextarea" */ '@/modules/cms/components/skeletons/SkeletonTextarea.vue'
      )
    ),
  },
};
</script>
